






import { Component, Vue, Watch, ProvideReactive } from 'vue-property-decorator';
import FlowViewer from '@/views/home/flow2/flow-viewer.vue';
import { readRuntimeTask } from '@/api/flow-design/FlowApi';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';

@Component({
  name: 'UserHomeFlowUndo',
  components: {
    FlowViewer
  }
})
export default class UserHomeFlowUndo extends Vue {
  operation: string = 'task_runtime';
  isShow: boolean = false;
  //点击流程节点 右侧属性配置
  @ProvideReactive() flowGraphDisable: any = 'top'; //top--表示从顶级禁用  partEnable--从组件中定义：表示挂载在该组件下的‘部分’启用

  get _taskDataId() {
    return this.$route.query.id;
  }

  private created(): void {
    if (this.$route.query.token) {
      StorageUtil.setSessionStorage('token', this.$route.query.token);
    }
    this.readTask();
    this.$nextTick(() => {
      this.isShow = true;
    });
  }

  readTask() {
    readRuntimeTask(this.$route.query.id as string).then((res: Record<any, any>) => {
      if (res.code != '1') {
        this.$message.error(res.message);
      }
    });
  }

  @Watch('_taskDataId')
  taskDataIdWatcher(val) {
    this.isShow = false;
    this.$nextTick(() => {
      this.isShow = true;
      this.readTask();
    });
  }
}
